import React, { useEffect, useState } from "react";
import ArrowBackIcon from "../.././assets/imgs/arrow-back-left.svg";
import { Colors } from "../Commons/Colors/Colors";
import { Paragraph } from "../Commons/Paragraph";
import { Layout } from "../Layouts/Layout";
import { useNavigate } from "react-router-dom";
import { Button } from "../ui/button";

import FeaturePlatformCard from "./FeaturePlatformCard/FeaturePlatformCard";
import EspnModal from "./EspnModal/EspnModal";
import SleeperModal from "./SleeperModal/SleeperModal";

import { useSupabaseAuth } from "../../../src/Context/AuthContext/AuthContext";
import { getSyncByUser } from "../../../src/Controllers/LeagueSync";
import { PLATFORMCARDS } from "../../config/constants/constants";
import WaveProgressBar from "../LeagueHubTeam/WaveProgressBar";

export const PlatformSelector: React.FC = () => {
  const navigate = useNavigate();

  const [espnModal, setEspnModal] = useState(false);
  const [sleeperModal, setSleeperModal] = useState(false);
  const { getUserData } = useSupabaseAuth();
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [featurePlatformCards, setfeaturePlatformCards] =
    useState(PLATFORMCARDS);
  const [openModalSync, setOpenModalSync] = useState(false);
  const [errorSync, setErrorSync] = useState<string | null>(null);
  const [sleeperUserName, setSleeperUserName] = useState("");
  const [disabledButton, setdisabledButton] = useState(true);

  const fetchCurrentUser = async () => {
    const response = await getUserData();

    if (response) {
      setCurrentUser(response?.data?.user);
    }
  };

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  const handleSubmit = () => {
    // Dummy action function
  };

  useEffect(() => {
    const fetchUserSync = async () => {
      const response = await getSyncByUser(currentUser?.id as string);

      if (response.status) {
        const counts: Record<string, number> = {};

        response.data?.user_settings.forEach(
          (setting: {
            fantasy_type: string;
            leagues_array: [{ sync: boolean }];
          }) => {
            const syncedLeaguesCount = setting.leagues_array?.filter(
              (league) => league.sync === true,
            ).length;
            counts[setting.fantasy_type] = syncedLeaguesCount;
          },
        );

        const PlatformCards = featurePlatformCards.map((card) => {
          card.sync =
            counts[card.fantasy_type] !== undefined
              ? counts[card.fantasy_type]
              : -1;
          return card;
        });

        setfeaturePlatformCards(PlatformCards);
      }
    };

    if (currentUser) {
      fetchUserSync();
    }
  }, [currentUser]);

  const showLeagues = () => {
    navigate(`/league-sync/SLEEPER`);
  };

  const handleYahooSync = () => {
    const client_id = process.env.REACT_APP_YAHOO_CLIENT_ID;
    // const redirect_uri = "oob";
    const redirect_uri = process.env.REACT_APP_YAHOO_REDIRECT_URI || "";
    const response_type = "code";
    const language = "en-us";
    const state = "redirect_uri";

    const authorizationUrl = `https://api.login.yahoo.com/oauth2/request_auth?client_id=${client_id}&redirect_uri=${encodeURIComponent(redirect_uri)}&response_type=${response_type}&language=${language}&state=${state}`;
    window.location.href = authorizationUrl;
  };

  const handlePlatformSelect = (PlatformId: number) => {
    const platform = featurePlatformCards.find(
      (platform) => platform.id === PlatformId,
    ) || { sync: -1 };
    switch (PlatformId) {
      case 1:
        //LoginEspn();
        setEspnModal(true);
        break;
      case 2:
        if (platform.sync >= 0) {
          navigate(`/league-sync/SLEEPER`);
        } else {
          setSleeperModal(true);
          setSleeperUserName("");
          setErrorSync(null);
          setdisabledButton(true);
        }
        break;
      case 3:
        handleYahooSync();
        break;
      default:
        break;
    }
  };

  return (
    <div>
      {openModalSync ? (
        <Layout>
          <WaveProgressBar
            text="Syncing Your Team.."
            subText={
              "We'll send you a notification when\nyou're team has fully loaded"
            }
            goBack={() => navigate("/home")}
          />
        </Layout>
      ) : (
        <Layout>
          <div className="flex flex-col h-full">
            <div className="flex items-center justify-between relative z-20">
              <div
                className="cursor-pointer rounded-[100px] w-[58px] h-[58px] flex justify-center items-center"
                style={{
                  background: Colors.backgroundSecundary,
                }}
                onClick={() => navigate("/home")}
              >
                <img src={ArrowBackIcon} alt="" />
              </div>
              <div className="cursor-pointer" onClick={() => navigate("/home")}>
                <Paragraph
                  text="Skip"
                  props={{ fontSize: "20px" }}
                  ParagraphType="Pragragh22"
                  color="textPrimary"
                />
              </div>
            </div>
            <div className="h-screen flex flex-col justify-between sm:flex-col">
              <div className="">

                <div className="w-full sm:w-full mt-5">
                  <div className="flex flex-col text-center justify-center">
                    <div className="sm:text-4xl text-3xl text-white font-medium">
                      Select the League you’d like to connect
                    </div>
                  </div>
                </div>
                <div className=" pb-5 pt-10">
                  <div
                    className="gap-3 grid "
                    style={{
                      borderRadius: "10px",
                      overflowY: "auto",
                    }}
                  >
                    {featurePlatformCards.map((PlatformData: any, index: any) => (
                      <div
                        key={index}
                        onClick={() => handlePlatformSelect(PlatformData?.id)}
                      >
                        <FeaturePlatformCard
                          PlatformSmallIcon={PlatformData?.icon}
                          PlatformName={PlatformData?.title}
                          PlatformId={PlatformData?.id}
                          PlatformSync={PlatformData?.sync}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="sm:w-full sm:mt-0 flex flex-col gap-5 justify-center text-center w-full mt-5">
                <Button
                  className="w-full h-[60px] text-base my-6"
                  onClick={handleSubmit}
                >
                  Continue
                </Button>
              </div>
            </div>
          </div>

          <SleeperModal
            open={sleeperModal}
            setOpen={setSleeperModal}
            showLeagues={showLeagues}
            openModalSync={openModalSync}
            setOpenModalSync={setOpenModalSync}
            errorSync={errorSync}
            setErrorSync={setErrorSync}
            sleeperUserName={sleeperUserName}
            setSleeperUserName={setSleeperUserName}
            disabledButton={disabledButton}
            setdisabledButton={setdisabledButton}
          ></SleeperModal>
        </Layout>
      )}
    </div>
  );
};
