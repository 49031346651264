import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Paragraph } from "../../Commons/Paragraph";
import { Button } from "../../ui/button";
import pop_up_bg from "../../../assets/imgs/players-to-start-modal.svg";

interface PlayersToStartProps {
  totalplayers: number;
  onUseACredit: (playersNumber: number) => void;
  onGoback: () => void;
}

const PlayersToStart: React.FC<PlayersToStartProps> = ({
  totalplayers,
  onUseACredit,
  onGoback,
}) => {

  const [players, setPlayers] = useState<number>(0);


  const onHandleGoback = (event: any) => {
    const target = event.target;
    if (target.id === 'container' || target.id === 'container1') {
      onGoback();
    }
  }

  const onHandleUseACredit = () => {
    if (players > 0) {
      onUseACredit(players);
    }
  }

  return (
    <AnimatePresence>

      <motion.div
        id="container"
        onClick={e => onHandleGoback(e)}
        className="fixed top-0 right-0 w-full h-screen z-30 flex justify-center"
        initial={{ opacity: 0.1 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0.1 }}
        transition={{ duration: 0.7, ease: "easeInOut" }}
        style={{
          background: "rgba(24, 26, 32, 0.50)",
          backdropFilter: "blur(9px)",
          WebkitBackdropFilter: "blur(9px)"
        }}
      >
        <motion.div
          id="container1"
          className="text-white flex justify-center items-end sm:items-center h-full lg:p-[14px] max-w-[500px]"
          initial={{ opacity: 0.1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0.1 }}
          transition={{ duration: 0.6, ease: "easeInOut" }}
        >
          <div
            className="rounded-[20px] rounded-b-none sm:rounded-b-[20px]"
            style={{
              background: "#212130",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            }}
          >
            <img
              src={pop_up_bg}
              alt="PlayersToStart Background"
              className="rounded-t-[20px] w-full max-h-[300px]"
            />
            <div className="mt-1.5 text-center p-5">
              <Paragraph
                text="How Many Players to Start? "
                props={{
                  fontSize: "23px",
                  fontWeight: "600",
                  fontStyle: "Italic",
                }}
                ParagraphType="Paragraph22"
                color="textPrimary"
                className="mb-1.5"
              />
              <Paragraph
                text="Select a number of players you’d like  "
                props={{
                  fontSize: "12px",
                  fontWeight: "400",
                }}
                className="opacity-50"
                ParagraphType="Paragraph22"
                color="textPrimary"
              />
              <Paragraph
                text="to start out of your selection."
                props={{
                  fontSize: "12px",
                  fontWeight: "400",
                }}
                className="opacity-50"
                ParagraphType="Paragraph22"
                color="textPrimary"
              />
              <div className=" py-5 pb-6 rounded-xl flex flex-wrap gap-3">
                {Array.from({ length: totalplayers }, (_, index) => (
                  <div className="flex gap-3 w-full" key={index}>
                    <div
                      className={`gap-[4px]  ${players == (index + 1) ? "bg-[#FFFFFF3A]" : "bg-[#FFFFFF1A]"} text-[17.44px] font-medium w-full h-[56px] p-[6.54px_8px] rounded-[34px] flex items-center justify-center`}
                      onClick={() => setPlayers(index + 1)}
                    >
                      {index + 1}
                    </div>
                  </div>
                ))}
              </div>
              <Button className="text-[17.44px] font-medium mb-5 w-full h-[56px]"
                onClick={() => onHandleUseACredit()}
              >
                Use a Credit
              </Button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default PlayersToStart;
