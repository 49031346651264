import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Colors } from "../Colors/Colors";
import ArrowDown from "../../../assets/imgs/arrow-right.svg";

interface Option {
  label: string;
  value: string;
  subLabel: string;
  imgSrc: string;
}

interface LeagueTeamSelectProps {
  options: Option[];
  placeholder?: string;
  onSelect: (value: any) => void;
  enabledAddNewTeam?: boolean;
}

const LeagueTeamSelect: React.FC<LeagueTeamSelectProps> = ({
  options,
  placeholder = null,
  onSelect,
  enabledAddNewTeam = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<any | null>(null);

  const handleSelect = (option: Option) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
  };

  useEffect(() => {
    if (options.length > 0 && !placeholder) {
      setSelectedOption(options[0]);
    }
  }, [options]);
  const truncateString = (str: string, limit: number): string => {
    return str?.length > limit ? str.slice(0, limit) + '...' : str;
  }

  return (
    <div
      className="relative w-full z-30 rounded-[13.08px] overflow-hidden min-h-[45px] flex flex-col justify-center"
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.10)",
        backdropFilter: isOpen ? "blur(75px)" : "",
      }}
    >
      <div
        className="cursor-pointer w-full flex items-center justify-center gap-2 p-2 text-white font-medium transition-all duration-500"
        onClick={() => {
          setSelectedOption(null);
          setIsOpen(!isOpen);
        }}
        style={{
          fontSize: "17px"
        }}
      >
        <div className="flex items-center justify-between gap-1">
          {/* {selectedOption && (
            <img
              src={selectedOption?.logo_url}
              alt={selectedOption.subLabel}
              className="w-4 h-4 rounded-full"
            />
          )} */}
          {
            truncateString(selectedOption?.name, 12) ?
          <span>
            {isOpen
              ? truncateString(selectedOption?.name, 12)
              : !isOpen && !selectedOption?.name
                ? truncateString(selectedOption?.name, 12)
                : truncateString(selectedOption?.name || '', 12)}
          </span>: <span>My Team</span>
          }

        </div>
        <img
          src={ArrowDown}
          alt="Arrow"
          className={`w-[21.079px] transform transition-transform duration-300 ${isOpen ? "-rotate-90" : "rotate-90"
            }`}
        />
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            {options.map((option: any) => (
              <div
                className="flex items-center gap-2 px-4 pb-2"
                key={option?.league_id}
                onClick={() => handleSelect(option)}
              >
                <img
                  src={option?.logo_url}
                  alt={option.subLabel}
                  className="w-4 h-4 rounded-full"
                />
                <div className="">
                  <div className="cursor-pointer text-white text-[10px]">
                    {option?.name}
                  </div>
                  <div className="text-white opacity-45 text-[6px]">
                    {"sleeper"}
                  </div>
                </div>
              </div>
            ))}

            {enabledAddNewTeam && (
              <div className="flex items-center gap-2 px-4 pb-2 text-white mb-2">
                <div className=" text-2xl font-[100] leading-[25px]">+</div>
                <div className="cursor-pointer  text-[10px] pt-1">
                  Add A New Team
                </div>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default LeagueTeamSelect;
