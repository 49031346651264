import React, { useEffect, useState } from "react";
import ArrowBackIcon from "../.././assets/imgs/arrow-back-left.svg";
import { Colors } from "../Commons/Colors/Colors";
import { Paragraph } from "../Commons/Paragraph";
import { Layout } from "../Layouts/Layout";
import { useNavigate } from "react-router-dom";
import FeatureLeagueCard from "./FeatureLeagueCard/FeatureLeagueCard";
import { useParams } from "react-router-dom";
import { useSupabaseAuth } from "../../Context/AuthContext/AuthContext";
import {
  getSyncByUserAndFantasyType,
  SleeperLeagueSync,
  SleeperLeagueUnSync,
  YahooLeagueSync,
  YahooLeagueUnSync,
} from "../../Controllers/LeagueSync";
import { capitalizeFirstLetter } from "../../lib/utils";

export const LeagueSync: React.FC = () => {
  const navigate = useNavigate();
  const { FantasyType } = useParams();
  const { getUserData } = useSupabaseAuth();
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [platformData, setPlatformData] = useState<any>(null);
  const [legues, setLegues] = useState<any>(null);
  const [featureLeagueCards, setFeatureLeagueCards] = useState<any>([]);

  const fetchCurrentUser = async () => {
    const response = await getUserData();

    if (response) {
      setCurrentUser(response?.data?.user);
    }
  };

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  useEffect(() => {
    if (currentUser) fetchUserSync();
  }, [currentUser]);

  const fetchUserSync = async () => {
    const response = await getSyncByUserAndFantasyType(
      currentUser?.id as string,
      FantasyType || "",
    );

    if (response.status) {
      const platform =
        response?.data?.user_settings.length > 0
          ? response?.data?.user_settings[0]
          : {};
      setPlatformData(platform);
      const leagues = platform?.leagues_array;
      // platformData?.yahoo_legue_data?.fantasy_content?.users?.user?.games
      //     ?.game?.leagues?.league;
      setLegues(leagues);

      if (leagues) {
        const leagueCards = leagues.map((league: any, index: any) => ({
          id: league.league_id,
          name: league.name,
          icon: league.logo_url,
          sync: league.sync,
        }));

        setFeatureLeagueCards(leagueCards);
      }
    }
  };

  console.log("yahooLegues", legues);

  const handleSubmit = () => {
    // Dummy action function
  };

  const handleLeagueSelect = async (id: string) => {
    switch (FantasyType) {
      case "ESPN":
        console.log("ESPN");
        break;
      case "SLEEPER":
        {
          const response = await SleeperLeagueSync(
            currentUser?.id,
            platformData.id,
            id,
          );

          if (response.status) {
            fetchUserSync();
            console.log(response);
          } else {
            alert("something wrog with the Sync, contact support please!!");
          }
        }
        break;
      case "YAHOO":
        {
          const response = await YahooLeagueSync(
            currentUser?.id,
            platformData.id,
            id,
          );

          if (response.status) {
            fetchUserSync();
            console.log(response);
          } else {
            alert("something wrog with the Sync, contact support please!!");
          }
        }
        break;
      case "yahoo":
        {
          const response = await YahooLeagueSync(
            currentUser?.id,
            platformData.id,
            id,
          );

          if (response.status) {
            fetchUserSync();
            console.log(response);
          } else {
            alert("something wrog with the Sync, contact support please!!");
          }
        }
        break;
    }
  };

  const handleLeagueSyncSelect = async (id: string) => {
    switch (FantasyType) {
      case "ESPN":
        console.log("ESPN");
        break;
      case "SLEEPER":
        {
          const response = await SleeperLeagueUnSync(
            currentUser?.id,
            platformData.id,
            id,
          );

          if (response.status) {
            fetchUserSync();
            console.log(response);
          } else {
            console.log(
              "something wrog with the Sync, contact support please!!",
            );
          }
        }
        break;
      case "YAHOO":
        {
          const response = await YahooLeagueUnSync(
            currentUser?.id,
            platformData.id,
            id,
          );

          if (response.status) {
            fetchUserSync();
            console.log(response);
          } else {
            console.log(
              "something wrog with the Sync, contact support please!!",
            );
          }
        }
        break;
      case "yahoo":
        {
          const response = await YahooLeagueUnSync(
            currentUser?.id,
            platformData.id,
            id,
          );

          if (response.status) {
            fetchUserSync();
            console.log(response);
          } else {
            alert("something wrog with the Sync, contact support please!!");
          }
        }
        break;
    }
  };

  const featureLeagueCardsSync = featureLeagueCards.filter(
    (card: any) => card.sync,
  );
  const featureLeagueCardsNotSycned = featureLeagueCards.filter(
    (card: any) => !card.sync,
  );

  return (
    <Layout>
      <div className="flex flex-col h-full">
        <div className="flex items-center justify-between relative z-20">
          <div
            className="cursor-pointer rounded-[100px] w-[58px] h-[58px] flex justify-center items-center"
            style={{
              background: Colors.backgroundSecundary,
            }}
            onClick={() => navigate(-1)}
          >
            <img src={ArrowBackIcon} alt="" />
          </div>
          <Paragraph
            text={`${capitalizeFirstLetter(FantasyType)} Sync`}
            props={{ fontSize: "28px", marginLeft: "-45px" }}
            ParagraphType="Pragragh22"
            color="textPrimary"
          />
          <div></div>
        </div>
        <div className="h-screen flex flex-col sm:flex-col">
          <div className="mt-9 pb-5 ">
            <Paragraph
              text="Active Leagues"
              className="text-[18px] md:text-[20px] xl:text-[22px] 2xl:text-[24.3px] leading-[110%] font-medium"
              ParagraphType="Paragraph22"
              color="textPrimary"
            />
            <div
              className="mt-5 gap-3 grid "
              style={{
                borderRadius: "10px",
                overflowY: "auto",
              }}
            >
              {featureLeagueCardsSync &&
                featureLeagueCardsSync.length > 0 ?
                featureLeagueCardsSync.map((LeagueData: any, index: any) => (
                  <div
                    key={LeagueData?.id}
                    onClick={() => handleLeagueSyncSelect(LeagueData?.id)}
                  >
                    <FeatureLeagueCard
                      LeagueSmallIcon={LeagueData?.icon}
                      LeagueName={LeagueData?.name}
                      LeagueId={LeagueData?.id}
                      LeagueSync={LeagueData?.sync}
                    />
                  </div>
                )): <div className="text-white opacity-65 text-center mt-5">--No league synced--</div>}
            </div>
          </div>
          <div className=" pb-5">
            <Paragraph
              text="Leagues Not Synced"
              className="text-[18px] md:text-[20px] xl:text-[22px] 2xl:text-[24.3px] leading-[110%] font-medium"
              ParagraphType="Paragraph22"
              color="textPrimary"
            />
            <div
              className=" mt-5 gap-3 grid "
              style={{
                borderRadius: "10px",
                overflowY: "auto",
              }}
            >
              {featureLeagueCardsNotSycned &&
                featureLeagueCardsNotSycned.length > 0 ?
                featureLeagueCardsNotSycned.map(
                  (LeagueData: any, index: any) => (
                    <div
                      key={index}
                      onClick={() => handleLeagueSelect(LeagueData?.id)}
                    >
                      <FeatureLeagueCard
                        LeagueSmallIcon={LeagueData?.icon}
                        LeagueName={LeagueData?.name}
                        LeagueId={LeagueData?.id}
                        LeagueSync={LeagueData?.sync}
                      />
                    </div>
                  ),
                ) : <div className="text-white opacity-65 text-center mt-5">--All leagues have been synced--</div>}

            </div>
          </div>
          <div></div>
        </div>
      </div>
    </Layout>
  );
};
