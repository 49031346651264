import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(...registerables);
Chart.register(ChartDataLabels);

interface BarChartData {
  label: string;
  value: number;
  color: string;
}

interface BarChartComponentProps {
  data: BarChartData[];
}

const BarChartComponent: React.FC<BarChartComponentProps> = ({ data }) => {
  const toOrdinal = (num: number): string => {
    const suffixes = ["th", "st", "nd", "rd"];
    const value = num % 100;
    return (
      num +
      (suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0])
    );
  };

  const rankedData = data
    .map((item, index) => ({ ...item, originalIndex: index }))
    .sort((a, b) => b.value - a.value)
    .map((item, index) => ({ ...item, rank: toOrdinal(index + 1) }))
    .sort((a, b) => a.originalIndex - b.originalIndex);
  const chartData = {
    labels: rankedData.map(item => item.label),
    datasets: [
      {
        label: 'Values',
        data: rankedData.map(item => item.value),
        backgroundColor: rankedData.map(item => item.color),
        borderColor: rankedData.map(item => item.color),
        borderRadius: 9.6,
        borderSkipped: false,
      },
    ],
  };

  const options = {
    responsive: true,
    layout: {
      padding: {
        top: 30,
      },
    },
    scales: {
      x: {
        display: true,
        ticks: {
          color: "white",
          font: {
            size: 16,
          },
          stepSize: 1,
          beginAtZero: true,
        },
      },
      y: {
        display: false,
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        color: "white",
        anchor: "end",
        offset: -30,
        align: "start",
        font: {
          size: 12,
        },
        formatter: (value: any, context: any) => rankedData[context.dataIndex]?.rank || "",
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default BarChartComponent;
