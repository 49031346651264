import React, { useEffect, useState } from "react";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CircularProgressBar: React.FC<{
  percentage: number;
  pathColor: string;
  fontSize?: string;
  suffix?: string;
  className?: string;
}> = ({
  percentage,
  pathColor,
  fontSize = "27px",
  suffix = "",
  className = "w-20 h-20"
}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let total = 100; 
    if (suffix.startsWith("/")) {
      const parsedTotal = parseInt(suffix.replace("/", ""), 10);
      if (!isNaN(parsedTotal)) {
        total = parsedTotal;
      }
    }

    setProgress((percentage / total) * 100);
  }, [percentage, suffix]);

  return (
    <div className={className}>
      <CircularProgressbarWithChildren
        value={progress}
        counterClockwise={true}
        styles={buildStyles({
          pathColor: pathColor,
          trailColor: "transparent",
        })}
      >
        <div
          style={{
            fontSize: fontSize,
            textAlign: "center",
            fontWeight: "bold",
            position: "relative",
            color: "white",
          }}
        >
          {Number.isNaN(progress) ? "--" : percentage}{suffix && <span className="text-[10.833px] font-semibold">{suffix}</span>}
        </div>
       
      </CircularProgressbarWithChildren>
    </div>
  );
};

export default CircularProgressBar;